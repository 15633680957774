<template>
    <div class="mainBody">
        <el-page-header @back="backPrivious" content="最新入驻企业"></el-page-header>
        <!-- <div class="search">
            <div class="search-options">
                <span style="margin-right: 30px;" @click="typeName = 'product'"
                    :class="typeName == 'product' ? 'pitchOn' : 'NopitchOn'">搜商品</span>
                <span @click="typeName = 'firm'" :class="typeName == 'firm' ? 'pitchOn' : 'NopitchOn'">搜企业</span>
            </div>
            <div style="margin-top: 30px;" class="f-c-c">
                <div v-if="typeName == 'product'" class="f-c search-input">
                    <el-select class="select-box" v-model="select" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                    <input type="text" placeholder="请输入内容" v-model.trim="inputText" @focus="inputFocus" @blur="inputBlur"
                        @keyup.enter="search" maxlength="10" />
                    <el-button class="search-icon" @click="handleImageUploadBox"><i class="el-icon-camera"></i></el-button>
                    <el-button class="search-icon2" @click="goToColourSearch"><img style="height: 22px;width: 22px;"
                            src="@/assets/colorSearchIcon.png" alt=""></el-button>
                </div>
                <div v-else class="f-c search-input2">
                    <input type="text" placeholder="请输入内容" v-model.trim="inputText" @focus="inputFocus" @blur="inputBlur"
                        @keyup.enter="search" maxlength="10" />
                </div>
                <el-button class="search-icon3" @click="search" icon="el-icon-search"></el-button>
            </div>
        </div> -->


        <!-- banner -->
        <div class="banner">
            <img src="@/assets/banner_newEnterprise.png" alt="">
        </div>
        <div class="breadcrumb">
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>最新入驻企业</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="list">
            <div class="title">
                <img src="../assets/newEnterprise.png" />
                <span class="text1">最新入驻企业</span>
                <span class="text2">/ new</span>
            </div>
            <div class="list_box f-w">
                <div class="enterpriseItem flex" v-for="(item, idx) in  newEnterpriseList" :key="idx"
                    @click="toEnterprise(item.enterpriseId)">
                    <div class="blue_part"></div>
                    <div class="right_part f-c">
                        <img class="enterpriseImg" :src="item.eimage1" alt="">
                        <div class="enterpriseInfo">
                            <div class="enterpriseName">{{ item.enterpriseName }}</div>
                            <div class="enterpriseCity">{{ item.city }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="search-container" v-show="showHistoryBox">
            <div class="input-history" :style="{ width: typeName == 'product' ? '380px' : '490px' }">
                <div class="f-c-b">
                    <span>历史记录</span>
                    <span class="delete-history" @click="deleteHistoryList"><i class="el-icon-delete"></i></span>
                </div>
                <div class="history f-c">
                    <template v-if="historyList.length && typeName == 'product'">
                        <el-tag class="history-item" v-for="(item, index) of historyList" :key="index" closable type="info"
                            @close="handleClose(item)" @click="historySearch(item)">{{ item }}</el-tag>
                    </template>
                    <template v-else-if="firmhistoryList.length && typeName == 'firm'">
                        <el-tag class="history-item" v-for="(item, index) of firmhistoryList" :key="index" closable
                            type="info" @close="firmhandleClose(item)" @click="historySearch(item)">{{ item }}</el-tag>
                    </template>
                    <div v-else class="history-no">暂无历史记录</div>
                </div>
            </div>
        </div> -->
        <!-- 登录 -->
        <DialogLogin :loginBoxVisible="loginBoxVisible" @CloseLoginBox="CloseLoginBox"></DialogLogin>
        <!-- 职业 -->
        <DialogStanding :jodBoxVisible="jodBoxVisible" @CloseJobBox="CloseJobBox"></DialogStanding>
        <!-- 图片搜索 -->
        <!-- <ImageUpload @closeUploadBox="closeUploadBox" v-show="showImageUploadBox" :imageUploadTrends="imageUploadTrends"
            :key="new Date().getTime()">
        </ImageUpload> -->
        <Pagination v-if="newEnterpriseList && newEnterpriseList.length" :total="total" :pageNum.sync="currentPage"
            :limit.sync="size" :layout="layout" @pagination="fetchData" />
    </div>
</template>
<script>
import { PaginationRetention1 } from '@/mixins/PaginationRetentionMixin';
// import { UnitAccount } from "@/utils/attributeValue1Options";
export default {
    name: 'MoreEnterprise',
    inject: ['reload'],
    mixins: [PaginationRetention1],
    components: {
        // ImageUpload: () => import(/* webpackChunkName: "ImageUpload" */ '@/components/ImageUpload.vue')
    },
    data() {
        return {
            state: '',
            title: '',
            Englishtitle: '',
            newEnterpriseList: [],
            loginBoxVisible: false,  // 登录弹窗
            jodBoxVisible: false,  // 职业弹窗
        }
    },
    created() {
        let InvestigateFlag = this.$getStorage('InvestigateFlag');
        this.jodBoxVisible = InvestigateFlag == 'Y' || InvestigateFlag == 'I' ? true : false;
        this.pagingSize = this.$getStorage('fetchDataSize') ? this.$getStorage('fetchDataSize') : this.size;
        this.paging = this.$getStorage('fetchDataPage') ? this.$getStorage('fetchDataPage') : this.page;
        this.getDate();
        this.$setStorage('isreload', true);
    },
    methods: {
        getDate() {
            this.findNewEnterpriseList(this.paging, this.pagingSize, this.state, this.select);
        },
        // 获取首页产品列表
        findNewEnterpriseList(page = this.page, size = this.size) {
            let that = this;
            that.$http.findNewEnterpriseList({
                // state,
                page,
                size,
                // productType

            }).then(res => {
                if (res.code == 200) {
                    let { records } = res.data;
                    // records.forEach(item => {
                    //     item.productTypeStr = UnitAccount.getproductType(item.productType)
                    // })
                    this.newEnterpriseList = records;
                    this.currentPage = res.data.current;
                    this.total = res.data.total;
                }
            })
        },
        // 跳转去供应商页面
        toEnterprise(enterpriseId) {
            if (!this.$getStorage('token')) {
                this.loginBoxVisible = true
            } else {
                this.$removeStorage('fetchDataPage')
                this.$removeStorage('fetchDataSize')
                this.$router.push({
                    name: 'SupplierInfoIndex',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            id: enterpriseId,
                            type: 'product',
                        }))
                    }
                })
            }
        },
        CloseLoginBox() {
            this.loginBoxVisible = false;
            if (this.$getStorage('token')) {
                let InvestigateFlag = this.$getStorage('InvestigateFlag');
                this.jodBoxVisible = InvestigateFlag == 'Y' || InvestigateFlag == 'I' ? true : false;
            }
        },
        // 关闭职业弹窗
        CloseJobBox() {
            this.jodBoxVisible = false;
            this.reload();
        },
    },
    beforeDestroy() {
        this.$removeStorage('fetchDataPage')
        this.$removeStorage('fetchDataSize')
    }
}

</script>

<style lang="less" scoped>
.mainBody {
    position: relative;

    .search {
        position: relative;
        // position: absolute;
        // right: 0;

        .search-options {
            // border: 1px solid red;
            position: absolute;
            top: -30px;
            left: 350px;


            .pitchOn {
                border-bottom: 3px solid #0270F2;
                padding: 5px;
                color: #333333;
                font-size: 14px;
                cursor: pointer;
            }

            .NopitchOn {
                color: #999999;
                font-size: 14px;
                padding: 5px;
                cursor: pointer;
            }
        }

        .search-input {
            position: relative;

            .select-box {
                width: 110px;
                position: absolute;
                left: 0;
            }

            input {
                background-color: #FFF;
                border-radius: 4px;
                border: 1px solid #E0E1E3;
                box-sizing: border-box;
                color: #606266;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                outline: 0;
                padding: 0 50px 0 110px;
                transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
                width: 500px;
            }

            .search-icon {
                width: 50px;
                height: 38px;
                line-height: 40px;
                text-align: center;
                line-height: 40px;
                position: absolute;
                right: 0;
                font-size: 20px;
                border: none;
                outline: none;
                border-left: 1px solid #E0E1E3;
                padding: 0;
                margin: 0;
            }

            .search-icon2 {
                width: 50px;
                height: 38px;
                line-height: 50px;
                position: absolute;
                right: 50px;
                border: none;
                outline: none;
                border-left: 1px solid #E0E1E3;
                padding: 0;
                margin: auto;
            }
        }

        .search-input2 {

            input {
                background-color: #FFF;
                border-radius: 4px;
                border: 1px solid #E0E1E3;
                box-sizing: border-box;
                color: #606266;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                outline: 0;
                padding: 0 50px 0 50px;
                transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
                width: 500px;
            }
        }

        .search-icon3 {
            // border: 1px solid red !important;
            width: 80px;
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
            color: #FFFFFF;
            background: linear-gradient(90deg, #0873F4 0%, #39A8F9 100%);
        }

        :deep(.el-input__inner) {
            border: 1px solid #E0E1E3;
            border-right: none !important;
        }

        .el-button {
            height: 40px;
            border: 1px solid #E0E1E3;
            border-left: none !important;
            border-radius: 0%;
        }

        el-button {
            border: none;
            margin-left: 5px;
        }
    }

    .banner {
        height: 100px;
        width: 1280px;
        margin: 40px 0;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .breadcrumb {
        padding-bottom: 30px;
        border-bottom: 1px solid #F8F8F8;

        .el-breadcrumb ::v-deep .el-breadcrumb__inner {
            color: #666666 !important;
            font-weight: 400 !important;
            font-size: 14px;
        }

        .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
            color: #0363FA !important;
            font-weight: 400 !important;
            font-size: 14px;
        }
    }

    .list {
        margin-top: 40px;

        .title {
            img {
                margin-right: 5px;
                width: 28px;
                height: 25px;
            }

            .text1 {
                color: #333333;
                font-size: 28px;
                margin-right: 15px;
            }

            .text2 {
                color: #999999;
                font-size: 16px;
            }
        }

        .list_box {
            width: 100%;
            margin-top: 20px;

            .enterpriseItem {
                width: 420px;
                height: 160px;
                border-radius: 10px;
                cursor: pointer;
                margin-bottom: 20px;
                margin-right: 6px;

                .blue_part {
                    width: 10px;
                    height: 160px;
                    background-color: #7CB3F5;
                    border-radius: 10px 0 0 10px;
                }

                .right_part {
                    width: 410px;
                    height: 160px;
                    border-radius: 0 10px 10px 0;
                    background-color: #F8F8F8;
                    padding: 0 20px;

                    .enterpriseImg {
                        height: 128px;
                        width: 128px;
                        border-radius: 10px;
                    }

                    .enterpriseInfo {
                        margin-left: 20px;

                        .enterpriseName {
                            font-size: 22px;
                            font-weight: 400;
                            color: #333333;
                        }

                        .enterpriseCity {
                            margin-top: 30px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                }
            }

        }

    }

    .search-container {
        position: absolute;
        top: 180px;
        right: 420px;
        z-index: 99;
        border-radius: 10px;

        .input-history {
            // width: 380px;
            border: 1px solid #e7e7e7;
            background-color: #ffffff;
            padding: 10px;
            border-radius: 5px;
            margin-top: 1px;

            .delete-history {
                display: inline-block;
                padding: 1px 15px;
            }

            .history {
                margin-top: 5px;
                padding: 5px;
                flex-wrap: wrap;

                .history-item {
                    // border: 1px solid;
                    background: #ffffff;
                    border-radius: 15px;
                    // padding: 6px 10px;
                    font-size: 14px;
                    // color: #fff;
                    margin: 10px 20px 0 0;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .history-no {
                    padding: 10px;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}
</style>